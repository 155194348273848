import emt from '../image1.webp';
import wheelchairImage from '../image5.webp';
import jimabazi from '../jimabazi.webp';
import edwardyamen from '../edwardyamen.webp';
import newImg from '../newimg.webp';

const About = () => {

    return (
        <>
        <div className="about-wrapper">
          <div className="about-grid-bg"></div>
          <div className="text-holder">
            <div className="title-tag">Learn more about</div>
            <div className="title">BOOM BOOM</div>
          </div>
          <div className="about-card">
              <div className="about-image">
                <img alt="Example of Boom Boom Medical Transport" src={emt}/>
              </div>
              <div className="about-text">
                Your premier provider of emergency medical transportation services. Dedicated to providing fast and reliable transportation for individuals in need of medical attention or transportation. 
              </div>
          </div> 
          <div className="about-card">
              <div className="about-text">
              Our team of highly trained and certified EMTs are equipped with state-of-the-art ambulances and medical equipment to ensure  that you receive the best possible care during your time of need.
              </div>
              <div className="about-image">
                <img alt="Example of Boom Boom Medical Transport" src={newImg}/>
              </div>
          </div> 
          <div className="about-card">
              <div className="about-image">
                <img alt="Example of Boom Boom Medical Transport" src={wheelchairImage}/>
              </div>
              <div className="about-text">
              Whether you are undergoing a medical emergency or simply need to be transported to a medical facility, Boom Boom Medical Transportation is here to help.
              </div>
          </div> 
        </div>
        <div className="team-wrapper">

        <div className="team-grid">
          <div className="team-card">
                <div className="team-title">Meet the<br/>team members.</div>
                <div className="team-title-desc">
                  Read about the current members behind Boom Boom Medical Transportation.
                </div>
          </div>

          <div className="team-card">
              <img alt="Edward Yamen, MD" src={edwardyamen}/>
              <div className="team-details">
                <div className="team-card-title">Edward Yamen, MD</div>
                <div className="team-card-role">Medical Director</div>
                <div className="team-desc">Medical director for Boom Boom Medical Transportation, vice president chairman of the emergency department at Hackensack University Medical Center and currently an emergency room physician.</div>
              </div>
          </div>

          <div className="team-card">
              <img alt="Jim Abazi, BSN" src={jimabazi}/>
              <div className="team-details">
                <div className="team-card-title">Jim Abazi, BSN</div>
                <div className="team-card-role">Chief Ambulance Officer</div>
                            <div className="team-desc">Jim Abazi, BSN, is an accomplished emergency/trauma technician with over two decades of experience at Hackensack University Medical Center. Graduating with a BSN RN in Nursing from William Paterson University, he has an extensive background in healthcare, including serving in Bergen County EMS from 1999 to 2003 and contributing to Fairlawn Volunteer Ambulance. A certified medical doctor in Eastern Europe, Jim is dedicated to ensuring patients have a secure and accessible transportation experience. His commitment to excellence was recognized in 2022-2023 when he was nominated as the Employee of the Year from the residency program at Hackensack Medical University.</div>
              </div>
          </div>
        </div>
            </div>
            <div className="apply-banner">
                <div className="grid">
                    <div className="apply-content">
                        <div className="apply-title">Join the team.</div>
                        <div className="apply-desc">Boom Boom is constantly looking for new employees to fill in the position of vehicle operators, EMTs, MAVT, dispatchers, and billing specialists.</div>
                        <a target="_blank" rel="noreferrer" href="https://forms.gle/rfhp8ZDy2c8mSRPp6" className="apply-button">Apply Now</a>
                    </div>
                    <iframe title="Boom Boom Medical Services Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1485.5334326676705!2d-74.24590464801736!3d40.898013130126394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c30193ab5aaaa9%3A0x6f1e11a524ca524b!2s50%20Galesi%20Dr%20Ste%20%238%2C%20Wayne%2C%20NJ%2007470!5e0!3m2!1sen!2sus!4v1697663765857!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
      </>
    );

}

export default About;