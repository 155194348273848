import { gsap } from 'gsap'; 
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLayoutEffect } from 'react';

const wheelchair = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 521 646"><path d="M223 1.22h13c8.47-.21 15.15 1.19 23 4.38 7.52 3.07 15.01 8.03 20.83 13.69 33.54 32.56 21.81 90.85-21.83 107.9-7.47 2.92-17.98 5.08-26 4.77-16.6-.64-34.36-7.82-45.83-20-26.82-28.45-23.62-75.99 8.83-98.92 8.56-6.04 17.75-9.71 28-11.82ZM292 372h100c16.89.2 30.31 5.02 39.53 20l18.72 37 35.01 69 22.99 45c3.43 6.87 10.17 19.1 11.85 26 5.96 24.51-14.3 50.65-40.1 48.68-25.51-1.95-32.08-17.39-42.25-37.68l-43-85-15.44-30c-1.56-2.88-4.42-9.57-6.7-11.4-2.37-1.92-5.73-1.59-8.61-1.6H240c-5.55-.01-15.75-.95-21-2.43-25.36-7.15-42.96-29.08-43-55.57V209c.01-6.99 1.14-13.51 3.87-20 16.89-40.1 69.32-48.67 97.95-16 14.47 16.52 14.18 35.61 14.18 56v63h86c9.27.01 16.57 1.43 23.96 7.52 15.02 12.36 12.82 35.45-1.96 47.09-6.62 5.22-12.86 6.38-21 6.39h-87v19ZM144 258v56c0 2.22.22 5.7-1.02 7.59-1.12 1.68-12.36 7.31-14.98 8.83-9.04 5.28-21.69 14.99-28.72 22.62-20.64 22.41-35.14 50.31-38.2 80.96v20c.06 18.48 5.44 37.53 13.67 54 22.91 45.8 68.27 74.75 119.25 76.96 5.73.25 7.64-.26 13-.79 25.11-2.51 43.67-8.23 65-22.25 19.15-12.58 36.28-31.51 46.74-51.92 2.42-4.72 5.54-11.98 7.26-17 .74-2.17 1.89-6.65 3.7-8.01 1.67-1.25 5.26-.99 7.3-.99h11c7.32.13 6.99 2.55 11.25 11l12 23c1.76 3.53 5.35 9.05 4.58 13-.5 2.53-5.33 11.38-6.83 14-5.85 10.23-12.38 19.93-19.92 29-28.88 34.72-68.8 58.74-113.08 67.6-6.58 1.32-20.51 3.32-27 3.4h-22c-31.32-.05-70.98-14.02-97-31.08C69.99 600.8 53.24 585.16 38.89 566c-55.77-74.48-50.89-176.35 8.7-247 22.61-26.81 61.83-53.19 96.41-61Z"/></svg>;
const doctor = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328 452"><path d="M155 1.21h6c33.87-.6 63.03 15.46 79.11 45.79 11.2 21.11 10 43.92 9.89 67-.18 37.42-20.34 70.16-56 83.58-10.03 3.77-21.3 5.54-32 5.42-38.03-.45-70.35-26.77-80.57-63-4.16-14.75-3.61-35.48-3.43-51 .16-13.58 3.28-28.81 9.37-41C101.26 20.23 125.14 5.93 155 1.21ZM165 273c3.23-6.02 8.27-10.87 12.72-16l23.33-27c2.8-3.15 10.47-13.27 13.99-14.23 2.16-.59 8.55 1.1 10.96 1.7 12.18 3.05 17.93 5.73 29 11.28 34.63 17.36 58.82 50 68.13 87.25 1.8 7.23 3.78 18.62 3.87 26v90c-.02 13.92-4.98 18.98-19 19H29c-5.54 0-15.23.76-19.98-1.69C1.87 445.63 1.01 439.17 1 432v-88c.03-17.2 6.13-40.64 13.81-56 14.77-29.57 38.13-50.85 68.19-64.14 6.74-2.98 13.85-5.18 21-6.97 2.23-.55 6.81-1.73 8.96-1.12 3.56 1 11.17 11.05 13.99 14.23l23.33 27c4.45 5.13 9.49 9.98 12.72 16h2Zm57.02 60.22c-10.4 4.64-1.13 18.7-6.62 24.18-2.37 2.38-10.99 1.58-14.4 1.6-12.73.09-10.06 8.24-10 21 .02 3.17-.2 7.12 2.43 9.4 2.19 1.91 5.83 1.59 8.57 1.6 10.83.02 14.82-1.49 15 8 .07 3.77-.87 12.76 1.6 15.49 3.16 3.5 14.71 2.53 19.4 2.51 3.17-.02 7.12.2 9.4-2.43 2.43-2.79 1.53-11.75 1.6-15.57.27-14.62 15.1-4.33 22.77-9.02 3.68-2.25 3.22-7.26 3.23-10.98.01-4.47 1.17-15.46-2.31-18.4-5.18-4.36-20.23 1.83-23.09-4.75-.66-1.5-.59-4.19-.6-5.85-.02-3.28.5-11.28-1.02-13.85-1.81-3.07-4.83-3.09-7.98-2.93-4.93-.31-13.35-.5-17.98 0Z"/></svg>;

const Services = () => {

    gsap.registerPlugin(ScrollTrigger);
    useLayoutEffect(() => {
    
      let ctx = gsap.context(() => {
    
        gsap.to(".services-container", {
          clipPath: "inset(0px round 0px 0px 0px 0px)",
          scrollTrigger: {
            trigger: ".services-container",
            start: "0px top",
            end: "500px top",
            scrub: true,
          }
        })
  
        gsap.to(".services-bg", {
          backgroundPosition: "50% 50%",
          scrollTrigger: {
            trigger: ".services-container",
            start: "top top",
            end: "bottom top",
            scrub: true,
          }
        })
  
        gsap.to(".about-wrapper", {
          clipPath: "inset(0px round 0px 0px 0px 0px)",
          scrollTrigger: {
            trigger: ".about-wrapper",
            start: "0px top",
            end: "500px top",
            scrub: true,
          }
        })
  
        gsap.set(".card", {
          autoAlpha: 0,
          y: 50,
        })
  
        ScrollTrigger.batch(".card", {
          interval: 0.01,
          duration: 1,
          start: "150px bottom",
          end: "bottom bottom",
          scrub: true,
          onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.1,}),
          onLeaveBack: batch => gsap.to(batch, {autoAlpha: 0, y: 50}),
        });
  
        gsap.to(".card.one", {
          scrollTrigger: {
            trigger: ".card.one",
            start: "0px bottom",
            end: "bottom bottom",
            onEnter: () => {
              document.querySelector(".services-bg.one").classList.add("visible");
              document.querySelector(".services-bg.two").classList.remove("visible");
                },
          }
        })
  
        gsap.to(".card.two", {
          scrollTrigger: {
            trigger: ".card.two",
            start: "150px bottom",
            end: "bottom bottom",
            onEnter: () => {
              document.querySelector(".services-bg.one").classList.remove("visible");
              document.querySelector(".services-bg.two").classList.add("visible");
            },
            onLeaveBack: () => {
              document.querySelector(".services-bg.one").classList.add("visible");
              document.querySelector(".services-bg.two").classList.remove("visible");
            }
          }
        })
  
        gsap.set(".about-card", {
          autoAlpha: 0,
          y: 50,
        })
  
        ScrollTrigger.batch(".about-card", {
          interval: 0.01,
          duration: 1,
          start: "150px bottom",
          bottom: "-150px bottom",
          scrub: true,
          onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.1,}),
          onEnterBack: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.1,}),
          onLeaveBack: batch => gsap.to(batch, {autoAlpha: 0, y: 50}),
          onLeave: batch => gsap.to(batch, {autoAlpha: 0, y: 50}),
        });
  
      });
      
      return () => ctx.revert(); // cleanup
      
    }, []);
  

    return (
        <>
        <div className="services-container">
        <div className="services-mask"></div>
        <div className="services-bg one"></div>
        <div className="services-bg two"></div>
        <div className="services-bg three"></div>
        <div id="services" className="services-wrapper maxed">
          <div className="wrapper-title">
            <div className="title-text">
              Explore<br/>our services.
            </div>
          </div>
          <div className="double-grid">
            <div className="grid-text">
              <div className="card one">
                <div className="card-icon">{wheelchair}</div>
                <div className="card-title">Mobility Assistance Vehicle Operator (MAVO)</div>
                                <div className="card-desc">
                                Boom Boom Medical Transportation prioritizes patients with our MAVO services, ensuring safe and timely transportation. Dedicated to their well-being, our trained professionals guarantee a secure journey, emphasizing reliability and comfort. Trust us for efficient medical transportation, where every moment matters in delivering care with precision and compassion.                                </div>
              </div>
              <div className="card two">
                <div className="card-icon">{doctor}</div>
                <div className="card-title">Basic Life Support (BLS)</div>
                                <div className="card-desc">
Boom Boom Medical Transportation delivers essential BLS services, prioritizing patient safety and punctuality. Our commitment ensures a secure and timely journey for individuals in need. Trust our dedicated team to provide reliable Basic Life Support, prioritizing the well-being of each patient with precision and compassion.                                </div>
                            </div>

            </div>
          </div>
        </div>
            </div>
            <div className="apply-banner">
                <div className="grid">
                    <div className="apply-content">
                        <div className="apply-title">Join the team.</div>
                        <div className="apply-desc">Boom Boom is constantly looking for new employees to fill in the position of vehicle operators, EMTs, MAVT, dispatchers, and billing specialists.</div>
                        <a target="_blank" rel="noreferrer" href="https://forms.gle/rfhp8ZDy2c8mSRPp6" className="apply-button">Apply Now</a>
                    </div>
                    <iframe title="Boom Boom Medical Services Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1485.5334326676705!2d-74.24590464801736!3d40.898013130126394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c30193ab5aaaa9%3A0x6f1e11a524ca524b!2s50%20Galesi%20Dr%20Ste%20%238%2C%20Wayne%2C%20NJ%2007470!5e0!3m2!1sen!2sus!4v1697663765857!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>
    );

}

export default Services;