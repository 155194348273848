import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {

    const form = useRef(null);
    const email = useRef(null);
    const name = useRef(null);
    const message = useRef(null);
    const flag = useRef(null);

    const sendEmail = (e) => {

        e.preventDefault();

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.current.value) === true && name.current.value.length >= 2 && message.current.value.length >= 2) {

            emailjs.sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TEMPLATE, form.current, process.env.REACT_APP_PUBLIC_KEY)
                .then((result) => {
                    form.current.classList.add("sent");
                    flag.current.innerText = "Your message has been sent.";
                }, (error) => {
                    form.current.classList.add("sent");
                    flag.current.innerText = `Error: ${error}`;
                });

        }
    }

    return (
        <>
        <div className="apply-banner contact">
            <div className="form-content">

                <div className="form-cell">
                    <div className="apply-title an">Contact Us</div>
                        <form ref={form} className="apply-form" onSubmit={(e) => { sendEmail(e); }}>
                            <div ref={flag} className="message"></div>
                        <div className="form-col an">
                            <div className="form-label">Name</div>
                                <input ref={name} name="name" type="text" placeholder="Enter your name"></input>
                        </div>
                        <div className="form-col an">
                        <div className="form-label">Email</div>
                                <input ref={email} name="email" type="text" placeholder="Enter your email"></input>
                        </div>
                        <div className="form-col double an">
                            <div className="form-label">Your Message</div>
                                <textarea ref={message} name="message" placeholder="Enter your message"></textarea>
                        </div>
                        <div className="form-col double contact an"><button className="apply-button contact">Send Message</button></div>
                    </form>
                </div>
            </div>
        </div>
        <div className="apply-banner">
              <div className="grid">
                <div className="apply-content">
                  <div className="apply-title">Join the team.</div>
                  <div className="apply-desc">Boom Boom is constantly looking for new employees to fill in the position of vehicle operators, EMTs, MAVT, dispatchers, and billing specialists.</div>
                          <a target="_blank" rel="noreferrer" href="https://forms.gle/rfhp8ZDy2c8mSRPp6" className="apply-button">Apply Now</a>
                </div>
                <iframe title="Boom Boom Medical Services Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1485.5334326676705!2d-74.24590464801736!3d40.898013130126394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c30193ab5aaaa9%3A0x6f1e11a524ca524b!2s50%20Galesi%20Dr%20Ste%20%238%2C%20Wayne%2C%20NJ%2007470!5e0!3m2!1sen!2sus!4v1697663765857!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
        </>
    )
}

export default Contact;