import {useLayoutEffect} from 'react';
import { gsap } from 'gsap'; 
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Routes, Route } from 'react-router-dom';

import Header from './Header/index.js';
import Services from './Pages/Services.js';
import Home from './Pages/Home.js';
import Contact from './Pages/Contact.js';
import About from './Pages/About.js';
import logo from './Header/logo2.webp';

const linkedin = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M18 .53 41 0h182c14 0 26.38-.08 31.89 16 1.23 3.58 1.1 6.31 1.11 10v197c0 14 .08 26.38-16 31.89-3.58 1.23-6.31 1.1-10 1.11H33c-14 0-26.38.08-31.89-16-1.23-3.58-1.1-6.31-1.11-10V35C0 19-.81 6.14 18 .53ZM56 28.3c-9.31 1.49-16.81 4.99-21.59 13.7C24.29 60.45 38.26 84.71 60 82.53c4.1-.41 8.44-1.37 12-3.51C95.28 65 86.21 26.71 56 28.3Zm84 81.7c0-3.95.95-11.57-2.31-14.26-2.46-2.03-6.66-1.74-9.69-1.74h-15c-9.55 0-14.83-.82-15 11v94c0 6.22-1.74 23.51 3.23 27.26 2.68 2.03 6.58 1.73 9.77 1.74h19c2.93-.04 6.3-.01 8.4-2.43 1.78-2.04 1.58-5.04 1.6-7.57v-55c.11-9.04 2.33-19.98 8.33-26.98 8.75-10.22 24.07-10.03 32.17.98 3.91 5.31 5.42 13.52 5.5 20v46c0 5.05-1.37 19.88 2.43 22.99 2.76 2.27 7.2 2 10.57 2.01h17c3.48-.01 8.74.34 11.26-2.51 2.06-2.33 1.74-6.55 1.74-9.49v-53c0-14.57-.03-31.4-5.87-45-3.31-7.7-8.04-14.24-15.13-18.89-14.1-9.28-39.29-9.36-54-1.47-6.57 3.53-9.15 7.1-14 12.36ZM43.02 94.21c-2.33 1.19-3.5 1.51-4.85 3.9-1.28 2.28-1.16 5.34-1.17 7.89v97c0 6.21-1.91 20.44 4.11 23.83 2.46 1.38 6.12 1.17 8.89 1.17 6.25.01 23.59 1.72 27.4-3.23 1.89-2.44 1.59-5.85 1.6-8.77V104c-.04-2.97.05-6-2.43-8.11-2.56-2.17-6.42-1.88-9.57-1.68H43.02Z" /></svg>;
const ig = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M142 .11h188c21.08-.11 42.27-1.08 63 3.55 35.3 7.9 66.93 28.45 88.63 57.34 8.21 10.94 16.08 25.09 20.68 38 5.72 16.06 9.66 33.9 9.69 51v212c-.13 80.79-69.21 149.87-150 150H150c-44.64-.07-87.42-22.02-115.72-56-14.43-17.34-26.3-41.83-30.83-64C1.8 383.92.01 370.15 0 362V148c.46-39.14 20.4-83.02 50-108.58 12.66-10.93 21.02-16.74 36-24.17 14.38-7.15 30.14-11.54 46-13.82l10-1.32Zm-2 51.99c-38.04 6-68.56 28.11-82.05 64.9-2.98 8.13-5.85 19.34-5.95 28v220c.08 49.44 45.56 94.92 95 95h218c49.44-.08 94.92-45.56 95-95V147c-.08-49.22-45.78-94.92-95-94.9H140Zm242 41.42c13.58-2.15 26.27 2.47 33.73 14.48 11.01 17.72.91 43.01-19.73 47.48-31.41 6.82-52.15-31.14-29.91-53.39 4.73-4.73 9.58-6.87 15.91-8.57Zm-141 30.69h22c14.19-.19 28.79 3.8 42 8.74 14.38 5.38 27.54 13.82 39 24 57.17 50.77 58.72 136.99 6.72 191.96-7.86 8.31-21.59 18.29-31.72 23.78-15.45 8.38-38.36 15.28-56 15.31h-16c-8.14-.1-17.15-2.05-25-4.12-38.27-10.09-70.34-36.63-86.69-72.88-6.06-13.44-11.29-33.24-11.31-48v-14c.01-9.18 1.78-18.16 4.12-27 9.39-35.62 32.4-65.09 64.88-82.69 15.71-8.52 30.53-12.25 48-15.1Zm8 51.07c-29.88 4.39-53.73 18.18-66.69 46.72-23.75 52.29 13.31 114.32 71.69 115 12.04.14 25-2.32 36-7.31 9.11-4.14 20.12-12.09 26.56-19.69 31.7-37.41 26.14-92.3-14.56-120.79-8.11-5.68-15.49-8.82-25-11.33-9.3-2.45-18.4-3.49-28-2.6Z"/></svg>;
const fb = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M253 512H69c-38.54-.06-68.94-30.46-69-69V67C.44 29.5 32 .06 69 0h376c37.5.44 66.94 32 67 69v376c-.44 37.5-32 66.94-69 67H333V369l1-15v-25h57c5.27-.01 9.73.11 13.58-4.21 3.37-3.78 3.92-10.88 4.47-15.79l3.37-29c.71-5.22 3.27-19.69 1.57-24-2.93-7.42-8.06-7.99-14.99-8h-57c-8.64-.02-8.98-.36-9-9v-41c.1-8.49 1.84-20.27 7.39-26.96 5.7-6.87 16.97-9.93 25.61-10.04h29c5.83-.07 11.61-.73 15.11-6.04 2.13-3.23 1.88-6.29 1.89-9.96v-41c-.02-9.51-2.5-14.82-13-15h-55c-9.5.11-20.03 2.65-29 5.67-7.38 2.48-16.73 6.92-23 11.49-20.4 14.85-32.87 37.02-36.71 61.84l-1.38 19-.91 12v49h-35c-4.12 0-12.55-.57-16 1.17-6.56 3.33-6.99 8.36-7 14.83v41c0 5.78-.86 14.09 2.7 18.89 3.8 5.14 9.57 5.1 15.3 5.11h41v161l-1 15v7Z"/></svg>;
function App() {
  gsap.registerPlugin(ScrollTrigger);
  useLayoutEffect(() => {
  
    let ctx = gsap.context(() => {

      gsap.set(".home-details", {
        autoAlpha: 0,
        yPercent: 50,
      })
      gsap.to(".home-details", {
        delay: 1,
        yPercent: 0,
        ease: "Power4.easeOut",
        autoAlpha: 1,
        duration: 1,
        stagger: 0.02,
      })

      gsap.to(".fade-mask", {
        autoAlpha: 0,
        delay: 0.5,
        duration: 3,
        ease: "Power4.easeOut",
      })

      gsap.set(".title-text", {
        autoAlpha: 0,
        y: 50,
      })

      gsap.to(".title-text", {
        scrollTrigger: {
          trigger: ".title-text",
          start: "100px bottom",
          end: "bottom bottom",
          duration: 1,
          onEnter: () => {gsap.to(".title-text", {autoAlpha: 1, y: 0, stagger: 0.1,})},
          onLeaveBack: () => {gsap.to(".title-text", {autoAlpha: 0, y: 50})},
        }
      })

      gsap.to(".services-container", {
        clipPath: "inset(0px round 0px 0px 0px 0px)",
        scrollTrigger: {
          trigger: ".services-container",
          start: "0px top",
          end: "500px top",
          scrub: true,
        }
      })

      gsap.to(".about-wrapper", {
        clipPath: "inset(0px round 0px 0px 0px 0px)",
        scrollTrigger: {
          trigger: ".about-wrapper",
          start: "0px top",
          end: "500px top",
          scrub: true,
        }
      })

      gsap.set(".card", {
        autoAlpha: 0,
        y: 50,
      })

      ScrollTrigger.batch(".card", {
        interval: 0.01,
        duration: 1,
        start: "150px bottom",
        end: "bottom bottom",
        scrub: true,
        onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.1,}),
        onLeaveBack: batch => gsap.to(batch, {autoAlpha: 0, y: 50}),
      });

      gsap.set(".about-card", {
        autoAlpha: 0,
        y: 50,
      })

      ScrollTrigger.batch(".about-card", {
        interval: 0.01,
        duration: 1,
        start: "150px bottom",
        bottom: "-150px bottom",
        scrub: true,
        onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.1,}),
        onEnterBack: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.1,}),
        onLeaveBack: batch => gsap.to(batch, {autoAlpha: 0, y: 50}),
        onLeave: batch => gsap.to(batch, {autoAlpha: 0, y: 50}),
      });

      ScrollTrigger.batch(".menu-content", {
        interval: 0.01,
        duration: 1,
        start: "150px bottom",
        bottom: "-150px bottom",
        scrub: true,
        onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.05,}),
        onEnterBack: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.05,}),
        onLeaveBack: batch => gsap.to(batch, {autoAlpha: 0, y: 50}),
      });

      gsap.set(".text-holder", {
        autoAlpha: 0,
        y: 50,
      })

      gsap.to(".text-holder", {
        scrollTrigger: {
          trigger: ".text-holder",
          start: "100px bottom",
          end: "bottom bottom",
          duration: 1,
          onEnter: () => {gsap.to(".text-holder", {autoAlpha: 1, y: 0, stagger: 0.1,})},
          onLeaveBack: () => {gsap.to(".text-holder", {autoAlpha: 0, y: 50})},
        }
      })

      gsap.set(".grid", {
        autoAlpha: 0,
        y: 50,
      })

      gsap.to(".grid", {
        scrollTrigger: {
          trigger: ".grid",
          start: "100px bottom",
          end: "bottom bottom",
          duration: 1,
          onEnter: () => {gsap.to(".grid", {autoAlpha: 1, y: 0, stagger: 0.1,})},
          onLeaveBack: () => {gsap.to(".grid", {autoAlpha: 0, y: 50})},
        }
      })

      gsap.set(".an", {
        autoAlpha: 0,
        y: 50,
      })

      gsap.to(".an", {
        scrollTrigger: {
          trigger: ".an",
          start: "100px bottom",
          end: "bottom bottom",
          duration: 1,
          onEnter: () => {gsap.to(".an", {autoAlpha: 1, y: 0, stagger: 0.1,})},
          onLeaveBack: () => {gsap.to(".an", {autoAlpha: 0, y: 50})},
        }
      })

      gsap.set(".team-card", {
        autoAlpha: 0,
        y: 50,
      })

      gsap.to(".team-card", {
        scrollTrigger: {
          trigger: ".team-card",
          start: "100px bottom",
          end: "bottom bottom",
          duration: 1,
          onEnter: () => {gsap.to(".team-card", {autoAlpha: 1, y: 0, stagger: 0.1,})},
          onLeaveBack: () => {gsap.to(".team-card", {autoAlpha: 0, y: 50})},
        }
      })

    });
    
    return () => ctx.revert(); // cleanup
    
  }, []);

  return (
    <>
      <Header/>
      <div className="content">
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      
        <div className='footer'>
          <div className="footer-content">
            <a href="." className="flex"><img alt="Boom Boom Logo" className="footer-logo" src={logo}/></a>
            <div className="footer-info">
              
              <div className="footer-cell">
                <div className="footer-title">Location</div>
                <div className="footer-text">
                  50 Galesi Dr, Ste. 8
                  <br/>Wayne, NJ 07470
                </div>
              </div>

              <div className="footer-cell">
                <div className="footer-title">Call Us</div>
                <div className="footer-text">
                  201-918-8777
                </div>
              </div>

              <div className="footer-socials">
                  <div className="social-url">
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com">{ig}</a>
                                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com">{fb}</a>
                                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/boom-boom-medical-transportation/">{linkedin}</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-line">© 2023 Boom Boom Medical Transportation</div>

          </div>
          </div>
    </>
  );
}

export default App;
